<template>
  <div id="app">

    <router-view/>
  </div>
</template>


<script>
// import Navbar from "./components/Navbar.vue"
import Navbar from "../src/components/Navbar.vue";
export default {
  name: 'App',
  components: {
    Navbar

  },
}
</script>

<style>
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  align-content: center;
  /* line-height: 64px; */
}


.inRow {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  justify-content: center;
}
.inCol {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mainPage {
  display: flex;
  flex-direction: column;
  /* max-width: 1440px; */
  min-height: 100vh;
  padding: 10px;
  /* background-color: red; */
}
</style>
