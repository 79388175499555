<template>
  <div class="mainPage">
    
    <div class="contentLine inRow" id="headLine">
      <div class="inCol" id="firstCol">
        <div class="Navbar inRow">
          <div class="inRow">
            <img id="logo" src="../assets/logo.png" alt="">
          </div>
          <div class="inRow">
            <!-- <a id="contactUs">Home</a>
            <a id="contactUs">&nbsp; | &nbsp;</a>
            <a id="contactUs"> Harga </a>
            <a id="contactUs">&nbsp; | &nbsp;</a>
            <a id="contactUs"> Kontak  </a> -->
          </div>
        </div>
        <p class="title" id="firstTitle"> Rental Mobil dan Pemandu Wisata Berpengalaman.</p>
        <p  class="title" id="secondTitle">Kami siap membawa anda pada petualangan tak terlupakan.</p>
        <div class="inRow" id="buttonLine">
          <a href="https://api.whatsapp.com/send?phone=628114109011&text=Halo%20First%20Move%2C%20saya%20mau%20rental%20mobil" target="blank">
            <button class="button" id="callus">Hubungi Kami <img id="wa" src="../assets/wa.png" alt=""></button>
          </a>
        </div>
      </div>
      <div class="inCol panorama" id="secondCol">
        <img id="panorama" src="../assets/panorama.png" alt="">
      </div>
    </div>
    <div class="secondLine inRow">
      <div class="inRow" id="boxContent">
        <div class="inCol">
          <p id="pengalaman">Lebih dari 10 tahun, menjadi mitra perjalanan yang andal. </p>
        </div>
        <div class="inCol">
          <p id="pengalaman2">Hadir memberi layanan rental mobil premium dengan tim driver dan pemandu wisata berpengalaman siap mengantar anda untuk menjelajahi setiap sudut destinasi impian.</p>
        </div>
      </div>
    </div>
    <div class="thridLine inRow" id="thirdCol">

        <img src="../assets//third.png" id="third" alt="">

      <div class="thrid inCol" id="secondCol" >
        <h2 id="titleThird">Keamanan dan Kenyamanan Prioritas Utama Kami.</h2>
        <p id="subThird">Kami prioritaskan keamanan, kenyamanan, dan kepuasan Anda dalam setiap perjalanan. Tim berpengalaman kami siap mengantar Anda dengan penuh kepercayaan.</p>
      </div>
    </div>
    <div class="fourthLine inRow">
      <div class="fourth" id="firstCol">
        <div class="forth" id="textBox">
          <p class="forth" id="title">Pengalaman Terbaik dalam Setiap Perjalanan.</p>
          <p class="forth" id="sub">Kami yakin setiap perjalanan harus menghadirkan kenangan tak terlupakan. Tim driver kami tak hanya mengantar, tetapi juga membagikan panduan berharga tentang destinasi Anda.</p>
        </div>
      </div>
      <div class="fourth inRow" id="secondCol">
        <img class="four" id="firstfour" src="../assets//thrid1.png" alt="">
        <img class="four" id="secondfour" src="../assets//third2.png" alt="">
      </div>
    </div>
    <div class="fifth inRow">
      <div class="inCol">
        <p class="fifth" id="title">Perjalanan Aman dan Penuh Kenyamanan</p>
        <p class="fifth" id="title">Dengan Berbagai Kendaraan Pilihan</p>
      </div>
    </div>
    <div class="sixthLine inCol" >
      <div class="cardRow inRow">
        <div class="cardBox inCol">
          <img class="cars" src="../assets/cars/avanza.png" alt="">
          <br>
          <div class="inCol">
            <p id="titleCard">Toyota Avanza / Xenia</p>
            <p id="subCard">Rp. 500.000</p>
            <p id="subCard">Include Driver + BBM</p>
          </div>
        </div>
        <div class="cardBox inCol">
          <img class="cars" src="../assets/cars/innova.png" alt="">
          <br>
          <div class="inCol">
            <p id="titleCard">Toyota Innova Reborn</p>
            <p id="subCard">Rp. 750.000</p>
            <p id="subCard">Include Driver + BBM</p>
          </div>
        </div>
        <div class="cardBox inCol">
          <img class="cars" src="../assets/cars/alphard.png" alt="">
          <br>
          <div class="inCol">
            <p id="titleCard">Toyota Alpard Transformer</p>
            <p id="subCard">Rp. 3.500.000</p>
            <p id="subCard">Include Driver + BBM</p>
          </div>
        </div>
        <div class="cardBox inCol">
          <img class="cars" src="../assets/cars/fortuner.png" alt="">
          <br>
          <div class="inCol">
            <p id="titleCard">Toyota Fortuner</p>
            <p id="subCard">Rp. 1.500.000</p>
            <p id="subCard">Include Driver + BBM</p>
          </div>
        </div>
        <div class="cardBox inCol">
          <img class="cars" src="../assets/cars/hiace.png" alt="">
          <br>
          <div class="inCol">
            <p id="titleCard">Toyota Hiace 16 Seat</p>
            <p id="subCard">Rp. 900.000</p>
            <p id="subCard">Include Driver + BBM</p>
          </div>
        </div>
        <div class="cardBox inCol">
          <img class="cars" src="../assets/cars/bus.png" alt="">
          <br>
          <div class="inCol">
            <p id="titleCard">Bus 45-50 Seat</p>
            <p id="subCard">Rp. 2.300.000</p>
            <p id="subCard">Include Driver + BBM</p>
          </div>
      </div>
      <div class="cardRow inRow">
        </div>

      </div>
    </div>
    <div class="seventhLine inCol">
      <p class="seven" id="title">Kata Orang Tentang Kami</p>
      <p class="seven" id="sub">Mereka puas di setiap sudut yang kami tempuh </p>
      <div class="cardRow inRow" id="comment">
        <div class="cardBox inCol" id="comment"> 
          <div class="seven inRow" id="profile">
            <img src="../assets/pp1.png" class="comment" id="profile" alt="">
            <p id="nickName">Gerson Lobo</p>
          </div>
          <p id="comment">Layanan rental mobil ini sungguh luar biasa. Mobilnya nyaman dan pengemudi profesional dan pelayanan pelanggan responsif.</p>
          <img src="../assets/bintang.png" id="bintang" alt="">
        </div>
        <div class="cardBox inCol" id="comment">
          <div class="seven inRow" id="profile">
            <img src="../assets/pp2.png" class="comment" id="profile" alt="">
            <p id="nickName">Nur Fati</p>
          </div>
          <p id="comment">Sewa mobil di sini selalu bikin puas. Mobilnya selalu bersih dan terawat, serta pengemudinya amanah. Harga juga bersaing. Top!</p>
          <img src="../assets/bintang.png" id="bintang" alt="">
        </div>
        <div class="cardBox inCol" id="comment">
          <div class="seven inRow" id="profile">
            <img src="../assets/pp3.png" class="comment" id="profile" alt="">
            <p id="nickName">Matias</p>
          </div>
          <p id="comment">Pengalaman sewa mobil di tempat ini   biasa. Mobilnya dalam kondisi nyaman. Saya merasa aman dan puas selama perjalanan. </p>
          <img src="../assets/bintang.png" id="bintang" alt="">
        </div>
      </div>
    </div>
    <div class="eightLine inCol">
      <div class="inRow">
        <div class="eight" id="firstCol">
          <p class="eight" id="title">Dengan Senang Hati Kami Menyambut Anda</p>
          <div class="eight inRow" id="buttonLine">
            <a href="https://api.whatsapp.com/send?phone=628114109011&text=Halo%20First%20Move%2C%20saya%20mau%20rental%20mobil" target="blank">

              <button class="button eight" id="callus">Hubungi Kami 
                <img class="eight" id="waFooter" src="../assets/wa2.png" alt="">
              </button>
            </a>
          </div>
        </div>
        <div class="eight" id="secondCol">
          <img src="../assets/operator.png" id="operator" alt="">
        </div>
      </div>
    </div>
    <div class="ninthLine inRow">
       <div class="nine inCol" id="firstCol">
        <img src="../assets/logo.png" class="nine" id="smallLogo" alt="">
        <p class="nine" id="alamat">Jl. Catur Warga, Mataram</p>
        <p class="nine" id="alamat"> Kec. Mataram, Kota Mataram, Nusa Tenggara Bar. 83121</p>
        <p class="nine" id="telepon"> 0811 - 410 9011 </p>
        
       </div>
       <div class="nine inCol" id="secondCol">
        <div class="nine inRow" id="mainLogo">
          <img src="../assets/mainLogo.png" id="mainLogo" alt="">
        </div>
        <p class="nine" id="copyRight">© 2023 First Move. All Rights Reserved.</p>
       </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  
}
</script>

<style scoped>

.mainPage {
  padding: 0px;
  background-color: #F5F5F5;
  /* background-color: aqua ; */
  /* align-items: center; */
  /* text-align: center; */
}
/* Navbarr */
.Navbar {
  background-color: transparent;
    /* padding: 20px 100px; */
    justify-content: space-between;

}

img#logo {
  max-width: 120px;
  /* margin-top: 170px; */
}
.contentLine{
  align-content: center;
  padding: 80px 120px;
  padding-bottom: 200px;
  background-color: rgb(23, 37, 185);
  background-size: 100%;
  /* height: 500px; */
  background-repeat: no-repeat;
}
#componenNavbar {
    /* max-height: 50px; */
    /* background-color: #dfe9f3 ; */
    min-width: 200px;
    max-width: 1440px;
    justify-content: space-between;
    flex-wrap: wrap;
}
#firstCol {

  justify-content: center;
  flex: 6;
}
#secondCol {
  flex: 4;
}
#contactUs {
  font-weight: 800;
  align-self: center;
}
#title{
  font-weight: 600;
  font-size: 20px;
}
img#panorama{
  max-width: 440px;
}
/* Head Line */
p {
  text-align: start;
  margin: 0;           
}
.title {
  font-weight: bold;
}

#firstTitle {
  font-size: 48px;
  font-weight: 800;
  color: #FFFFFF;
  /* height: 30px; */
  /* line */
}
#secondTitle {
  margin-top: 18px;
  color: #FFFFFF;
}


.button{
  border-radius: 8px;
  border: none;
  padding: 16px 32px;
  font-size: 16px;
}
#buttonLine {
  justify-content: flex-start;
  margin-top: 32px;
}
#callus{

  background-color: #56B448;
  color: #FFFFFF;
  margin-right: 16px;
  line-height: normal;

}

img#wa {
  width: 24px;
  margin-left: 8px;
}

/* secondLine */
.secondLine {
  padding:0px 120px ;
}

.secondLine #boxContent{
  justify-content: space-between;
  padding: 60px 150px;
  /* border: 1px rgb(194, 190, 190) solid; */
  background-color: #E9F9E7;
  border-radius: 8px;
  align-items: center;
  position: relative;
  top: -100px;

}
.secondLine p{
  line-height: normal;
}
#pengalaman {
  flex: 5;
  font-weight: 800;
  font-size: 36px;
}

#pengalaman2{
  flex: 8;
  font-size: 18px;
}

/* third */

.thridLine {
  line-height: normal;
  padding: 0px 120px;
  /* background-color: #d21818; */
  margin-bottom: 20px;
}
img#third{
  max-width: 50%;
  position: relative;
  /* justify-self: center; */
  align-self: center;
  /* right: -100px; */

}

.thrid#secondCol{
  align-content: center;
  align-self: center;
  margin-left: 10%;
}

#titleThird {
  /* font-size: 36px; */
  line-height: normal;
  font-weight: bold;
  text-align: left;
}

/* Forth */
.fourthLine {
  padding: 0px 120px;
  margin-top: 100px;
  margin-bottom: 100px;
}
p.forth{
  line-height: normal;
}

.fourth#firstCol {
  /* justify-content: center; */
  flex: 4;
  /* align-content: center; */
  align-self: center;
}
.forth#textBox{
  padding: 40px;
  background-color: #FFFFFF;
  border-radius: 16px;
  margin-right: -100px;
  position: relative;
}

.fourth#secondCol {
  flex: 5;
}
#secondCol img{
  max-width: 50%;
}

.forth#title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
}
.forth#sub {
  font-size: 16px;
}
/* fifth */
.fifth {
  padding: 0 120px;
  margin-bottom: 70px;
}

.fifth#title{
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0px;
}

/* Sixth */

.sixthLine{
  padding: 0px 120px;
  margin-bottom: 70px;
}
.cardRow {
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.cardBox{
  background-color: #FFFFFF;
  width: calc(33.33% - 10px);
  padding: 2%;
  border-radius: 8px;
  margin: 2%;
}
img.cars{
  align-self: center;
  max-width: 306px ;
  min-width: 300px;
  height: 200px;
}

#titleCard {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  line-height: normal;
}
#subCard {
  font-size: 16px;
  text-align: left;
  color:#505050 ;
  line-height: normal;
}

/* seven */
.seventhLine{
  padding: 0px 120px;
  line-height: normal;
  margin-bottom: 87px;
}

.seven#title{
  font-size: 36px;
  font-weight: bold;
}
.seven#sub{
  font-size: 18px;
  margin-bottom: 32px;
}
.seven#profile {
  justify-content:start;
  align-items: center;
}

img#profile {
  max-width: 40px;
  margin-right: 10px;
}

#nickName {
  font-size: 18px;
  font-weight: bold;
}
.seven#profile{
  margin-bottom: 16px;
}

p#comment{
  font-size: 16px;
  color: #505050;
  margin-bottom: 16px;
}

img#bintang{
  max-width: 70px;
}

/* eight */
.eightLine {
  line-height: normal;
  padding: 0px 120px;
  padding-top: 60px;
  background-color: #56B448;
}
.eight#firstCol{
  align-self: center;
}
.eight#title{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 48px;
}

.eight#callus{
  background-color: #FFFFFF;
  color: #56B448;
  font-weight: bold;
  margin-right: 16px;
}


#waFooter{
  color: #56B448;
  line-height: normal;
  max-width: 24px;
  margin-left: 8px;
}
img#operator {
  /* width: 100%; */
  /* height: auto;
  height: 100%; */
  /* width: 364px;
  height: 364px; */
}

/* nine */

.ninthLine {
  line-height: normal;
  background-color: #1E2025;
  padding: 80px 120px; 
}

.nine#firstCol{
  align-self: center;
}

.nine#mainLogo{
  justify-content: end;
  margin-bottom: 52px;
}
.ninthLine p {
  color: #FFFFFF;
}

.nine#smallLogo {
  width: 150px;
  margin-bottom: 24px;
}

#telepon{
  margin-top: 24px;
}

#copyRight{
  text-align: end;
}
@media screen and  (max-width: 1080px) {
  .panorama#secondCol{
    display: none;
  }

  .contentLine {
    padding: 118px 16px ;
    padding-bottom : 200px;
  }
  .title#firstTitle {
    font-size: 24px;
  }
  .title#secondTitle {
    font-size: 16px;
    font-weight: normal;
    margin-top: 8px;
  }

  #buttonLine {
    margin-top: 16px;
  }

  .button#callus{
    padding: 12px 24px;
    font-size: 14px;
  }

  img#wa {
    width: 16px;
  }

  /* second */
  .secondLine {
    padding:0px 16px ;
    line-height: normal;
  }

  .secondLine #boxContent {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #FFFFFF;
    top: -80px;
  }

  p#pengalaman {
    font-size: 18px;
    margin-bottom: 8px;
  }
  p#pengalaman2 {
    font-size: 14px;
  }

  /* third */
  .thridLine {
    padding:0px 16px ;
    flex-direction: column;
  }
img#third{
  min-width: 100%;
  align-self: center;
  
  /* background-color: #505050; */

}

.thrid#secondCol{
  align-content: center;
  align-self: center;
  margin-left: 0px;
  margin-top: 8px;
  /* background-color: #505050; */
}

#titleThird {
  font-size: 18px;
  line-height: normal;
  font-weight: bold;
  text-align: left;
  /* background-color: #56B448; */
}

#subThird {
  font-size: 14px;
}

/* four */

.fourthLine {
  padding: 0px 16px;
  line-height: normal;
  flex-direction:column-reverse;
  margin-top: 60px;
}

.fourth#firstCol {
  align-self: center;
}
.forth#textBox{
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 16px;
  margin: 0;
  margin-top: 16px;
}

.forth#title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.forth#sub {
  font-size: 14px;
}

/* fifth */
.fifth {
  padding: 0px 16px;
  margin-bottom: 16px;
  margin-top: 58px;
  line-height: normal;
}

.fifth#title{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0px;
}

/* sixth */
.sixthLine{
  padding: 0px 16px;
  line-height: normal;
  margin-bottom: 5px;
}
.cardRow {
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.cardBox{
  background-color: #FFFFFF;
  width: calc(80% - 10px);
  padding: 2%;
  border-radius: 8px;
  margin: 2%;
}
img.cars{
  align-self: center;
  max-width: 50% ;
  max-height: 70%;
  /* min-width: 300px; */
  /* height: 200px; */
}

#titleCard {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  line-height: normal;
}
#subCard {
  font-size: 14px;
  text-align: left;
  color:#505050 ;
  line-height: normal;
}

/* Seven */
.seventhLine{
  padding: 0px 12px;
  line-height: normal;
  margin-bottom: 64px;
}
.cardRow#comment {
  flex-direction: column;
  padding: 0px;
}
.seven#title{
  font-size: 18px;
  font-weight: bold;
}
.seven#sub{
  font-size: 14px;
  margin-bottom: 14px;
}

.cardBox#comment {
  width: calc(100%);
  /* flex: 1; */

}
.seven#profile {
  justify-content:start;
  align-items: center;
  margin-bottom: 12px;
}

img#profile {
  max-width: 30px;
  margin-right: 10px;
}

#nickName {
  font-size: 16px;
  font-weight: bold;
}
.seven#profile{
  margin-bottom: 14px;
}

p#comment{
  font-size: 14px;
  color: #505050;
  margin-bottom: 16px;
}

img#bintang{
  max-width: 70px;
}

/* eight */
.eightLine {
  line-height: normal;
  padding: 50px 16px;
  /* padding-top: 50px; */
  background-color: #56B448;
}
.eight#firstCol{
  align-self: center;
}
.eight#secondCol{
  display: none;
}
.eight#title{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 24px;
}

.eight#callus{
  background-color: #FFFFFF;
  color: #56B448;
  font-weight: normal;
  margin-right: 14px;
}


#waFooter{
  color: #56B448;
  line-height: normal;
  max-width: 16px;
  margin-left: 8px;
}

/* ninth */
.ninthLine {
  line-height: normal;
  flex-direction: column;
  background-color: #1E2025;
  padding: 40px 16px; 
}

.nine#firstCol{
  align-self: flex-start;
}

.nine#mainLogo{
  display: none;
}
.ninthLine p {
  color: #FFFFFF;
}

.nine#smallLogo {
  width: 100px;
  margin-bottom: 14px;
}

#telepon{
  margin-top: 4px;
}

#copyRight{
  text-align: start;
  margin-top: 60px;
}

p.nine {
  font-size: 14px;
}
}
</style>