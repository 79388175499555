<template>
  <div class="Navbar inRow">
      <div class="inRow">
        <!-- <img src="../assets/1.png" alt=""> -->
        <p id="title">FIRST MOVE RENT</p>
      </div>
      <div class="inRow">
        <div class="inRow">
          <a id="contactUs">Home</a>
          <a id="contactUs">&nbsp; | &nbsp;</a>
          <a id="contactUs"> Harga </a>
          <a id="contactUs">&nbsp; | &nbsp;</a>
          <a id="contactUs"> Kontak  </a>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>

<style scoped>
.Navbar {
    /* max-height: 50px; */
    background-color: #D9D9D9 ;
    padding: 20px 100px;
    justify-content: space-between;
    flex-wrap: wrap;
}

img{
  max-width: 70px;
}

#componenNavbar {
    /* max-height: 50px; */
    /* background-color: #dfe9f3 ; */
    min-width: 200px;
    max-width: 1440px;
    justify-content: space-between;
    flex-wrap: wrap;
}

p{
  margin: 0;
  align-self: center;
}
#contactUs {
  font-weight: 400;
  align-self: center;
}
#title{
  font-weight: 600;
  font-size: 20px;
}
</style>